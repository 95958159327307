<template>
  <div class="homepage">
    <header>
      <div class="logo">
        <img src="@/assets/logo.png" alt="手游辅助 Logo">
        <span>手游辅助</span>
      </div>
      <nav>
        <!-- 导航内容 -->
      </nav>
    </header>

    <main>
      <div class="search-bar">
        <input type="text" v-model="searchQuery" placeholder="搜索游戏...">
      </div>

      <div class="categories">
        <button :class="{ active: currentSort === 'hot' }" @click="sortGames('hot')">热门</button>
        <button :class="{ active: currentSort === 'latest' }" @click="sortGames('latest')">最新</button>
        <button :class="{ active: currentSort === 'az' }" @click="sortGames('az')">A-Z</button>
        <button :class="{ active: currentSort === 'upcoming' }" @click="sortGames('upcoming')">即将支持</button>
      </div>

      <div v-if="isLoading" class="loading">加载中...</div>
      <div v-else-if="error" class="error">错误: {{ error }}</div>
      <div v-else class="game-list">
        <div class="game-item" v-for="game in filteredAndSortedGames" :key="game.id">
          <div class="game-image-and-name">
            <div class="game-image" :style="getGameImageStyle(game.image)">
              <img v-if="game.image" :src="game.image" :alt="game.name" @error="onImageError($event, game)">
            </div>
            <h3>
              <a :href="game.downloadLink" target="_blank">{{ game.name }}</a>
            </h3>
          </div>
          <div class="game-details">
            <div class="feature-count">{{ game.featureCount }} 个功能</div>
            <div class="downloads">{{ game.downloads }} 下载</div>
            <div class="game-platform">{{ game.platform }}</div>
            <div class="last-update">{{ getRelativeTime(game.lastUpdate) }}</div>
            <div class="game-status" :class="game.status">
              <span v-if="game.status === '可用'">✓ </span>{{ game.status }}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HomePage',
  data() {
    return {
      games: [],
      currentSort: 'hot',
      searchQuery: '',
      isLoading: false,
      error: null
    }
  },
  created() {
    this.fetchGames()
  },
  computed: {
    filteredAndSortedGames() {
      let result = this.games

      // 进行过滤
      if (this.searchQuery.trim() !== '') {
        const query = this.searchQuery.toLowerCase()
        result = result.filter(game => 
          game.name.toLowerCase().includes(query) ||
          game.platform.toLowerCase().includes(query)
        )
      }

      // 进行排序
      switch (this.currentSort) {
        case 'latest':
          return result.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate))
        case 'az':
          return result.sort((a, b) => a.name.localeCompare(b.name))
        case 'upcoming':
          return result.filter(game => game.status === '即将支持')
        case 'hot':
        default:
          return result // 假设默认顺序就是热门排序
      }
    }
  },
  methods: {
    async fetchGames() {
      this.isLoading = true
      this.error = null
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/games`)
        if (response.data.error_code === 0) {
          this.games = response.data.games.map(game => ({
            ...game,
            downloads: this.formatDownloads(game.downloads)
          }))
        } else {
          throw new Error(response.data.message || '获取游戏列表失败')
        }
      } catch (error) {
        this.error = error.message
        console.error('获取游戏列表时出错:', error)
      } finally {
        this.isLoading = false
      }
    },
    formatDownloads(downloads) {
      if (downloads >= 100000000) {
        return Math.floor(downloads / 100000000) + '亿+'
      } else if (downloads >= 10000000) {
        return Math.floor(downloads / 10000000) + '千万+'
      } else if (downloads >= 1000000) {
        return Math.floor(downloads / 1000000) + '百万+'
      } else if (downloads >= 10000) {
        return Math.floor(downloads / 10000) + '万+'
      } else {
        return downloads.toString()
      }
    },
    sortGames(sortType) {
      this.currentSort = sortType
    },
    getRelativeTime(dateString) {
      const now = new Date();
      const updateDate = new Date(dateString);
      const diffTime = Math.abs(now - updateDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays === 0) {
        return "今天";
      } else if (diffDays === 1) {
        return "昨天";
      } else if (diffDays < 7) {
        return `${diffDays}天前`;
      } else if (diffDays < 30) {
        const weeks = Math.floor(diffDays / 7);
        return `${weeks}周前`;
      } else if (diffDays < 365) {
        const months = Math.floor(diffDays / 30);
        return `${months}个月前`;
      } else {
        const years = Math.floor(diffDays / 365);
        return `${years}年前`;
      }
    },
    getGameImageStyle(image) {
      return image ? {} : { backgroundColor: this.getRandomColor() };
    },
    getRandomColor() {
      const colors = ['#1a1a2e', '#16213e', '#0f3460', '#1f1f1f', '#2c3e50'];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    onImageError(event, game) {
      event.target.style.display = 'none';
      game.image = null; // 将图片 URL 设为 null，触发背景色占位符
    }
  }
}
</script>

<style scoped>
.homepage {
  color: white;
  font-family: Arial, sans-serif;
  flex-grow: 1; /* 添加这行，使 HomePage 填充剩余空间 */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #24252a;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-size: 20px;
  font-weight: bold;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.download-btn {
  background-color: #8eff00;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

main {
  padding: 20px;
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  background-color: #2a2b30;
  color: white;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #8eff00;
  color: black;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.categories {
  display: flex;
  margin-bottom: 20px;
}

.categories button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #2a2b30;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.categories button.active {
  background-color: #3a3b40;
}

.game-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.game-item {
  background-color: rgba(36, 37, 42, 0.7);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.game-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: rgba(46, 47, 52, 0.9);
}

.game-image-and-name {
  position: relative;
}

.game-image-and-name img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.game-item:hover .game-image-and-name img {
  transform: scale(1.05);
}

.game-image-and-name h3 {
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  transition: background-color 0.3s ease;
}

.game-item:hover .game-image-and-name h3 {
  background-color: rgba(0, 0, 0, 0.9);
}

.game-image-and-name h3 a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: color 0.3s ease;
}

.game-image-and-name h3 a:hover {
  color: #8eff00;
}

.game-details {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background-color: rgba(30, 31, 36, 0.8);
  transition: background-color 0.3s ease;
}

.game-item:hover .game-details {
  background-color: rgba(40, 41, 46, 0.9);
}

.feature-count, .downloads, .game-platform, .last-update, .game-status {
  font-size: 14px;
}

.downloads {
  color: #8eff00;
}

.game-status.可用 {
  color: #8eff00;
}

.game-status.不可用 {
  color: #ff4d4d;
}

/* 其他样式保持不变 */

.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: #ff4d4d;
}

.game-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.game-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
